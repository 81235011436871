import React from 'react';

function Home() {
  return (
    <div className="home">
     this is your home page
    </div>
  );
}

export default Home;
